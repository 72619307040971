import './App.css';
import {Route, Routes} from "react-router-dom";
import { NavbarDR, SocialMediaRightPanel} from './components';
import { Footer} from './containers';
import { Home, About, AntiWrinkle, DermalFiller, Booking , Contact, Terms, Privacy, Microneedling, FatDissolving, SkinBoosters, SkinLesion, Polynecleotides, Blog} from './pages';
import ReactGA from 'react-ga';
import SkinPeels from './pages/skinpeels/Skinpeels';
import Pricing from './pages/pricing/pricing.page';
import CryotherapyPage from './pages/cryotherapy/cryotherapy.page';

const App = () => {

  const trackingId = "G-BXM5E79E1X"
  ReactGA.initialize(trackingId);

  return (
    <div className="root">
      <div>
        <NavbarDR />
      </div>
      <div>
          <Routes>
            <Route path ="/" element={<Home />}></Route>
            <Route path ="/Home" element={<Home />}></Route>
            <Route path ="/About" element={<About />}></Route>
            <Route path ="/AntiWrinkleInjections" element={<AntiWrinkle />}></Route>
            <Route path ="/DermalFiller" element={<DermalFiller />}></Route>
            {/* <Route path ="/Contact" element={<Contact />}></Route> */}
            <Route path ="/Microneedling" element={<Microneedling />}></Route>
            <Route path ="/Skinpeels"  element={<SkinPeels />}></Route>
            <Route path ="/Booking" element={<Booking />}></Route>
            <Route path ="/Terms" element={<Terms />}></Route>
            <Route path ="/Privacy" element={<Privacy />}></Route>
            <Route path = "/Pricing" element ={<Pricing />}></Route>
            <Route path = "/FatDissolving" element ={<FatDissolving />}></Route>
            <Route path = "/Cryotherapy" element ={<CryotherapyPage />}></Route>
            <Route path = "/SkinBoosters" element ={<SkinBoosters />}></Route>
            <Route path = "/SkinLesion" element ={<SkinLesion />}></Route>
            <Route path = "/Polynucleotides" element ={<Polynecleotides />}></Route>
            <Route path = "/Blog" element ={<Blog />}></Route>
          </Routes> 
      </div>
      <Footer />
      <SocialMediaRightPanel />
    </div>
  );
}

export default App;
